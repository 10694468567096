module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zach-Ferguson","short_name":"ZF","start_url":"/","background_color":"#124559","display":"minimal-ui","icon":"src/images/icons8-z-64 (1).png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78db14c252b09b3e6fc227c6dd15c588"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
